<!--
 * @Descripttion: 
 * @version: 
 * @Author: 张志浩
 * @Date: 2021-09-14 16:40:31
 * @LastEditors: 张志浩
 * @LastEditTime: 2022-01-18 16:17:35
-->
<template>
  <div ref="container" class="newsContainer">
    <div class="title">授权名片</div>
    <div class="tableContainer">
      <el-row>
        <el-col class="table">
          <el-table
            :data="tableData.slice((current - 1) * 10, current * 10)"
            border
            header-cell-class-name="headerClass"
            cell-class-name="cellClass"
            style="width: 100%"
          >
            <el-table-column
              label="微信昵称"
              min-width="150"
              prop="nickname"
              align="center"
            />

            <el-table-column
              label="姓名"
              min-width="156 "
              prop="name"
              align="center"
            />
            <el-table-column
              label=" 职位"
              min-width="118 "
              prop="position"
              align="center"
            />
            <el-table-column
              label="联系电话"
              min-width="154"
              prop="mobile"
              align="center"
            />
            <el-table-column
              label="申请原因"
              min-width="250"
              prop="reason"
              align="center"
            />
            <el-table-column label="操作" min-width="200" align="center">
              <template slot-scope="scope">
                <template v-if="scope.row.status == 0">
                  <el-button
                    size="mini"
                    type="danger"
                    @click="confirm(scope.row, 2)"
                    >拒绝</el-button
                  >

                  <el-button
                    size="mini"
                    type="primary"
                    @click="confirm(scope.row, 1)"
                    >通过</el-button
                  >
                </template>
                <el-button
                  size="mini"
                  type="danger"
                  v-if="scope.row.status == 2"
                  >已拒绝</el-button
                >
                <el-button
                  size="mini"
                  type="success"
                  v-if="scope.row.status == 1"
                  >已通过</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <!-- </div> -->
        </el-col>
      </el-row>
      <div class="pagination" style="text-align: center">
        <el-pagination
          :total="tableData.length"
          :page-size="10"
          layout="prev, pager, next"
          @current-change="clickPage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { getApplyCardsList, updateApplyCards } from "@/apis/user";
// import { parseTime } from "@/utils/index";
export default {
  data() {
    return {
      tableData: [],
      current: 1,
    };
  },
  activated() {
    this.getList();
  },
  methods: {
    async getList() {
      var res = await getApplyCardsList();
      this.tableData = res.result;
    },
    clickPage(e) {
      console.log(e);
      this.current = e;
    },
    confirm(row, e) {
      this.$alertMsgBox(
        "请确认是否要" + (e == 1 ? "同意授权" : "拒绝授权") + "?"
      )
        .then(() => {
          this.newsLoading = true;
          updateApplyCards({ applyId: row.applyId, status: e }).then(() => {
            this.$message1(e == 1 ? "同意授权" : "拒绝授权", "success");
            row.status = e;
            this.newsLoading = false;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/mixin";
.newsContainer {
  // height:calc(100vh - 118px);
  // position:relative;
  padding: 40px 0 40px 0;
  .title {
    letter-spacing: 2px;
    font-size: 22px;
    line-height: 1;
    color: #333;
    font-weight: bold;
    padding-left: 34px;
  }
  .tableContainer {
    border: none;
    .table {
      margin: 0;
    }
  }
}
</style>
